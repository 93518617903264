// DEV: check if block exists
import faq from './../components/faq'
import lightBox from '../components/lightBox'
import { initFormValidation } from '../components/form'

import './../../scss/main.scss'

import toggleMenu from './../components/toggleMenu'

document.fonts.ready.then(function() {
    document.body.classList.remove('font-fallback')
})

document.addEventListener('DOMContentLoaded', () => {
    faq()
    toggleMenu()
    lightBox()
    initFormValidation()
})

// eslint-disable-next-line no-undef
if (BS_ON === 'true' && NODE_ENV === 'development') {
    const script = document.createElement('script')
    // eslint-disable-next-line no-undef
    script.src = `http://localhost:${BS_PORT}/browser-sync/browser-sync-client.js`
    document.head.appendChild(script)
}

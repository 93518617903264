import SimpleLightbox from 'simplelightbox'
import 'simplelightbox/src/simple-lightbox.scss'

const lightBox = () => {
    const sliderGallery = document.querySelectorAll('.bar-slider__slider')
    sliderGallery.forEach((slider) => {
        // eslint-disable-next-line no-unused-vars
        const lightboxBarSlider = new SimpleLightbox(slider.querySelectorAll('.gallery'), {
            uniqueImages: false,
        })
    })

    // Array of common image extensions
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp']

    // Select all anchor tags with href pointing to an image
    const imageLinks = Array.from(document.querySelectorAll('a')).filter((a) => {
        const href = a.getAttribute('href')

        if (a.closest('.gallery')) {
            return false
        }

        return imageExtensions.some((ext) => href && href.includes(ext))
    })

    // Initialize SimpleLightbox on each image link individually
    imageLinks.forEach((link) => {
        // eslint-disable-next-line no-unused-vars
        const lightbox = new SimpleLightbox(link, {})
    })
}
export default lightBox

export default function faq() {
    const faqs = document.querySelectorAll('.schema-faq-section')

    faqs?.forEach((item) => {
        const trigger = item.querySelector('.schema-faq-question')
        const content = item.querySelector('.schema-faq-answer')

        trigger.addEventListener('click', () => {
            trigger.classList.toggle('schema-faq-question--active')
            content.classList.toggle('schema-faq-answer--active')
        })
    })
}

/* eslint-disable no-undef */
export const SUCCESS_MESSAGE = data.form.success_message
export const SUCCESS_MESSAGE_SHORT = data.form.success_message_short
export const ERROR_MESSAGE = data.form.error_message
export const ERROR_CORP_MAIL = data.form.error_corp_mail
export const ERROR_MAIL = data.form.error_mail
export const ERROR_FIELD = data.form.error_field
export const PHONE_SHORT = data.form.phone_short
export const PHONE_LONG = data.form.phone_long
export const ERROR_NUMBERS_ONLY = data.form.error_numbers_only
export const ACCEPTANCE_FALSE = data.form.acceptance_false
export const CAPTCHA_FALSE = data.form.captcha_false
